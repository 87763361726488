.mainContainer {
    width: 100%;
}

.container {
    width: inherit;
    border: solid #71343c 2px;
    border-radius: 1rem;
    margin: 2rem 0 0 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.container:hover {
    cursor: pointer;
}

.image {
    width: 8rem;
    height: 8rem;
    margin: 0 1rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.container h2 {
    text-align: center;
    color: black;
    margin: 1rem 0 0 0;
    font-size: 1.2rem;
    font-weight: bold;
}

.textContainer {
    flex-basis: 80%;
    overflow: auto;
}

@media only screen and (min-width: 768px) {
    .mainContainer {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .container {
        margin: 0 2rem;
        width: 28rem;
    }
}
