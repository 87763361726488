@import url(https://fonts.googleapis.com/css2?family=Tinos&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
.Screen_container__3MZ9t {
    width: 100%;
    min-height: 60vh;
    padding: 4rem 2rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Screen_hexagon__2IMR_ {
    margin: 2rem 0;
    height: 2rem;
    width: 2rem;
}

.Screen_grenatLine__38eNV {
    width: 10rem;
    height: 3px;
    background-color: #71343c
}

.Screen_innerCenteredContainer__3528w {
    flex-basis: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.Screen_centeredFlexText__2bY1W {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
}

.Screen_leftCenteredText__2izV2 {
    text-align: left;
    width: 100%;
    margin: 0 0 1rem 0;
}

.Screen_introLogo__6Lu-7 {
    flex-basis: 100%;
    width: 25vw;
    height: 25vw;
    margin: 0 2rem;
}

.Screen_aboutPicture__u45qH {
    width: 100%;
    height: 22rem;
    border-radius: 25px;
    background-position: top;
    background-size: cover;
}

.Screen_aboutText__2qX8r {
    text-align: justify;
}

.Screen_contactIconsContainer__2M-1v {
    width: 100%;
}

.Screen_contactIconsContainer__2M-1v p {
    font-weight: bold;
}

.Screen_contactLine__g8nIc {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem 0;
}

.Screen_contactLine__g8nIc img {
    width: 2.5rem;
    height: 2.5rem;
    margin: 0 1rem 0;
}

@media only screen and (max-width: 768px) {
    .Screen_aboutWrapper__v0BlE button {
        margin: 0 2rem;
    }
}

@media only screen and (min-width: 768px) {
    .Screen_introLogo__6Lu-7 {
        width: 15rem;
        height: 15rem;
    }
    .Screen_aboutWrapper__v0BlE {
        display: flex;
        align-items: center;
        width: 100%;
    }
    .Screen_aboutPicture__u45qH {
        flex-basis: 30%;
        width: 12rem;
        margin: 0 3rem 0 0;
    }
    .Screen_aboutText__2qX8r {
        flex-basis: 70%;
    }
}





.SkillSquare_mainContainer__280kr {
    width: 100%;
}

.SkillSquare_container__LZ0W0 {
    width: inherit;
    border: solid #71343c 2px;
    border-radius: 1rem;
    margin: 2rem 0 0 0;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.SkillSquare_container__LZ0W0:hover {
    cursor: pointer;
}

.SkillSquare_image__Db2K6 {
    width: 8rem;
    height: 8rem;
    margin: 0 1rem;
    background-size: contain;
    background-position: center;
    background-repeat: no-repeat;
    transition: transform 1s ease-in-out, opacity 1s ease-in-out;
}

.SkillSquare_container__LZ0W0 h2 {
    text-align: center;
    color: black;
    margin: 1rem 0 0 0;
    font-size: 1.2rem;
    font-weight: bold;
}

.SkillSquare_textContainer__3kK_M {
    flex-basis: 80%;
    overflow: auto;
}

@media only screen and (min-width: 768px) {
    .SkillSquare_mainContainer__280kr {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    .SkillSquare_container__LZ0W0 {
        margin: 0 2rem;
        width: 28rem;
    }
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

main {
  background-size: contain;
  background-position: center;
  background-attachment: fixed;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: 'Tinos', serif;
  font-size: 2.5rem;
  font-weight: normal;
  margin: 1rem 0;
}

button {
  font-family: 'Tinos', serif;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  color: black;
  width: 15rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  margin: 1rem;
  border: solid #71343c 2px;
  transition: background-color 0.2s ease-in, color 0.5s ease-out;
}

button:hover {
  cursor: pointer;
  background-color: #71343c;
  color: white;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282828;
  padding: 2rem 0;
}

footer p {
  flex-basis: 100%;
  color: white;
}

footer div {
  display: flex;
}

footer a {
  margin: 1rem;
}

footer a img {
  width: 2rem;
  height: 2rem;
  margin: 0;
}

footer img {
  width: 4rem;
  height: 4rem;
  margin: 1rem 0;
}

footer span {
  font-size: 0.9rem;
  color: white;
}

footer span a {
  text-decoration: none;
  margin: 0 0 0 0.2rem;
}

.mapIcon {
  background-color: white;
  border: none;
  border-radius: 50px;
  padding: 5px;
}

@media only screen and (min-width: 768px) {
  html {
    background-color: #f1f1ed;
  }

  body {
    padding: 0 15vw;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  button {
    width: 10rem;
  }
}
