@import url('https://fonts.googleapis.com/css2?family=Tinos&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap');

html,
body {
  padding: 0;
  margin: 0;
  font-family: 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  scroll-behavior: smooth;
}

main {
  background-size: contain;
  background-position: center;
  background-attachment: fixed;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

h1 {
  font-family: 'Tinos', serif;
  font-size: 2.5rem;
  font-weight: normal;
  margin: 1rem 0;
}

button {
  font-family: 'Tinos', serif;
  font-size: 1.2rem;
  font-weight: bold;
  background-color: transparent;
  color: black;
  width: 15rem;
  height: 3.5rem;
  border-radius: 0.5rem;
  margin: 1rem;
  border: solid #71343c 2px;
  transition: background-color 0.2s ease-in, color 0.5s ease-out;
}

button:hover {
  cursor: pointer;
  background-color: #71343c;
  color: white;
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #282828;
  padding: 2rem 0;
}

footer p {
  flex-basis: 100%;
  color: white;
}

footer div {
  display: flex;
}

footer a {
  margin: 1rem;
}

footer a img {
  width: 2rem;
  height: 2rem;
  margin: 0;
}

footer img {
  width: 4rem;
  height: 4rem;
  margin: 1rem 0;
}

footer span {
  font-size: 0.9rem;
  color: white;
}

footer span a {
  text-decoration: none;
  margin: 0 0 0 0.2rem;
}

.mapIcon {
  background-color: white;
  border: none;
  border-radius: 50px;
  padding: 5px;
}

@media only screen and (min-width: 768px) {
  html {
    background-color: #f1f1ed;
  }

  body {
    padding: 0 15vw;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1600px) {
  button {
    width: 10rem;
  }
}